module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-material-design-for-bootstrap","short_name":"Logo Menu","start_url":"/","background_color":"#d4d5d6","theme_color":"#ee1C25","display":"standalone","icon":"src/pages/images/favicon.png","icons":[{"src":"/static/favicons/favicon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/static/favicons/favicon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/static/favicons/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/static/favicons/favicon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/static/favicons/favicon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/static/favicons/favicon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/static/favicons/favicon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/static/favicons/favicon-512x512.png","sizes":"512x512","type":"image/png"}],"include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"691bc65fb55a3c7a3bc2f3f43060b1de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N8V7XL7","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
